import configTailwind from '../../../configTailwind';

// Could be an utils
const parseTailwindBreakpoint = () => {
  const { screens } = configTailwind.theme;
  const screenParsed = { ...screens };

  screenParsed.sm = parseInt(screens.sm.replace('px', ''), 10);
  screenParsed.md = parseInt(screens.md.replace('px', ''), 10);
  screenParsed.lg = parseInt(screens.lg.replace('px', ''), 10);
  screenParsed.xl = parseInt(screens.xl.replace('px', ''), 10);
  screenParsed['2xl'] = parseInt(screens['2xl'].replace('px', ''), 10);

  return screenParsed;
};

export default parseTailwindBreakpoint;
